"use strict"

document.addEventListener('DOMContentLoaded', function () {
	const form = document.getElementById('form');
	const modalForm = document.getElementById("modalForm");
	const modalFormSuccess = document.getElementById("modalFormSuccess");
	const modalFormError = document.getElementById("modalFormError");
	const modalFormFill = document.getElementById("modalFormFill");
	const modalFormClose = document.getElementById("modalFormClose");
	form.addEventListener('submit', formSend);
	const industry = form.elements["industry"];
	// const countryOfIncorporation = form.elements["countryOfIncorporation"];
	
	modalForm.style.display = "none";
	modalFormSuccess.style.display = "none";
	modalFormFill.style.display = "none";
	modalFormError.style.display = "none";

	async function formSend(e) {
		e.preventDefault();

		let error = formValidate(form);

		let formData = new FormData(form);

		if (error === 0) {
			form.classList.add('_sending');
			let response = await fetch('sendmail.php', {
				method: 'POST',
				body: formData,	
			});
			if (response.ok) {
				let result = await response.json();
				console.log(result.message);
				form.reset();
				form.classList.remove('_sending');
				modalForm.style.display = "block";
				modalFormSuccess.style.display = "block";
				overlay.classList.add("is-show");
				document.body.style.overflow = 'hidden';
			} else {
				console.log("Mistake");
				form.reset();
				form.classList.remove('_sending');
				modalForm.style.display = "block";
				modalFormError.style.display = "block";
				overlay.classList.add("is-show");
				document.body.style.overflow = 'hidden';
			}
		} else {
			console.log('Fill in all the fields ');
			modalForm.style.display = "block";
			modalFormFill.style.display = "block";
			overlay.classList.add("is-show");
    		document.body.style.overflow = 'hidden';
		}

	}

	modalFormClose.onclick = function() {
		modalForm.style.display = "none";
		modalFormSuccess.style.display = "none";
		modalFormFill.style.display = "none";
		modalFormError.style.display = "none";
		overlay.classList.remove("is-show");
		document.body.style.overflow = 'auto';
	  }

	function formValidate(form) {
		let error = 0;
		let formReq = document.querySelectorAll('._req');

		for (let index = 0; index < formReq.length; index++) {
			const input = formReq[index];
			formRemoveError(input);

			if (input.classList.contains('_email')) {
				if (emailTest(input)) {
					formAddError(input);
					error++;
				}
			} else if (input.getAttribute("type") === "checkbox" && input.checked === false && industry.selectedIndex === 0) {
				formAddError(input);
				error++;
			}  else {
				if (input.value === '') {
					formAddError(input);
					error++;
				} 
				
			}
		}
		return error;
	}
	function formAddError(input) {
		input.parentElement.classList.add('_error');
		input.classList.add('_error');
	}
	function formRemoveError(input) {
		input.parentElement.classList.remove('_error');
		input.classList.remove('_error');
	}
	function emailTest(input) {
		return !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,8})+$/.test(input.value);
	}

});



